<template>
  <NuxtLayout name="base">
    <div class="base">
      <div class="inner-page">
        <slot />
      </div>
      <div class="help-footer-extended" />
    </div>
  </NuxtLayout>
</template>

<style lang="scss" scoped>
@use 'pkg:@temperworks/styles/colors' as *;
@use 'pkg:@temperworks/styles/breakpoints' as *;
@use 'pkg:@temperworks/styles/sizes' as *;
.base {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  background: $grey100;
  min-height: calc(100vh - 56px);
  padding: env(safe-area-inset-bottom, $doubleOff);

  @include tab {
    padding: env(safe-area-inset-bottom, $none);
  }

  .inner-page {
    padding: 80px $triple $none;
    width: 100%;
    z-index: 2;
    min-height: calc(100vh - 56px);
    margin-right: auto;
    margin-left: auto;

    @include sm {
      padding: $quadruple
    }

    @include md {
      padding: $sixfold;
    }

    @include tab {
      min-height: calc(100vh - 64px);
      padding: 88px $triple $none;
    }

    @include lg {
      align-items: center;
      padding: 64px $none 120px;
      width: 1090px;
    }

    @include xl {
      padding: 64px $none 120px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .help-footer-extended {
    @include tab {
      position: absolute;
      background: $purple100;
      height: 251px;
      width: 100%;
      border-radius: 120px 120px $none $none;
      bottom: 0;
      z-index: 1;
    }
  }
}

.text-only :deep(.btn-outline) {
  border: none !important
}

.modal-title {
  text-align: center !important;
  margin-bottom: $singleOff;
}

.modal-body-intro {
  text-align: center;
  margin-top: $singleOff;
  color: $grey800;
  margin-bottom: $doubleOff;
}
</style>
